import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { EVENT_TYPE } from "../customercontextlayer/enums/event-type";
import { fetchTrackingCookieService } from "./services/tracking-cookie";
import { addTmsTrackingForChooseMyStoreButton } from "./tracking-tms";
export function addContextBannerEvent() {
  var contextBannerSelectors = document.querySelectorAll("[data-selectorid='context-banner']");
  contextBannerSelectors.forEach(function (contextBannerSelector) {
    var postcodeBannerSelector = contextBannerSelector.querySelector(".js-context-postcode-button");
    var storeHeaderSelector = contextBannerSelector.querySelector(".js-context-store-name-button");
    var openContextLayerEvent = new Event("openStoreContextLayer");
    postcodeBannerSelector === null || postcodeBannerSelector === void 0 || postcodeBannerSelector.addEventListener(EVENT_TYPE.CLICK, function () {
      addTmsTrackingOnContextBanner();
      window.dispatchEvent(openContextLayerEvent);
    });
    storeHeaderSelector === null || storeHeaderSelector === void 0 || storeHeaderSelector.addEventListener(EVENT_TYPE.CLICK, function () {
      addTmsTrackingOnContextBanner();
      window.dispatchEvent(openContextLayerEvent);
    });
  });
}
function addTmsTrackingOnContextBanner() {
  var buttonLocationValue = "Header";
  fetchTrackingCookieService(buttonLocationValue, function () {
    addTmsTrackingForChooseMyStoreButton("Choose my store", "Contextualisation", buttonLocationValue);
  });
}
import "core-js/modules/web.timers.js";
import { EVENT_TYPE } from "../customercontextlayer/enums/event-type";
export function initAutoContextNotification(notificationSelector) {
  var notifCloseButtonSelector = notificationSelector.querySelector(".js-auto-context-notification__button-close");
  var notificationLink = notificationSelector.querySelector("[data-selectorid=\"autocontext-popin-link\"]");
  showAutoContextToast(notificationSelector, true);
  notifCloseButtonSelector.addEventListener(EVENT_TYPE.CLICK, function () {
    showAutoContextToast(notificationSelector, false);
  });
  notificationLink.addEventListener(EVENT_TYPE.CLICK, function () {
    window.dispatchEvent(new Event("openStoreContextLayerInSearchMode"));
  });
  setTimeout(function () {
    showAutoContextToast(notificationSelector, false);
  }, 7000);
}
function showAutoContextToast(notificationSelector, toShow) {
  notificationSelector.classList.add(toShow ? 'show' : 'hide');
  notificationSelector.classList.remove(toShow ? 'hide' : 'show');
}